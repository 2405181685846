import React from 'react';
// import gql from 'graphql-tag';
// import { graphql } from 'react-apollo';
import Markdown from 'react-markdown';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import Gallery from '../components/Gallery';

import RelatedPlaceCard from '../components/RelatedPlaceCard';
import RelatedPersonCard from '../components/RelatedPersonCard';
import RelatedInvestigationCard from '../components/RelatedInvestigationCard';
import RelatedTourCompanyCard from '../components/RelatedTourCompanyCard';
import RelatedBookCard from '../components/RelatedBookCard';


import Layout from '../components/Layout';
import SEO, {SEODescription, SEOImage} from '../components/SEO';

import { graphql } from 'gatsby';


const useStyles = makeStyles((theme) => ({
  articleContainer: {
    padding: theme.spacing(2),
  },
  section: {
    paddingTop: theme.spacing(2),
  },
}));

const Lore = ({ data }) => {
  const classes = useStyles();
  const lore = data.gcms.lore;

  return (
    <Layout>

      <SEO 
        title={lore.title}
        description={SEODescription(lore.synopsis.text)}
        image={SEOImage(lore.gallery[0].handle)}
        article={true}
      />

      <Paper className={classes.articleContainer}>
        <article>
          <Typography variant="h3" gutterBottom={true}>
            {lore.title}
          </Typography>
          <Gallery gallery={lore.gallery} />
          <Markdown source={lore.synopsis.markdown} escapeHtml={false} />
          <Divider variant="middle" />
          {lore.people.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                People in the story
                </Typography>
              <Grid container spacing={1}>
                {lore.people.map(person => (
                  <RelatedPersonCard
                    key={person.id}
                    person={person}
                  />
                ))}
              </Grid>
            </section>
          }

          {lore.places.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Places in the story
                </Typography>
              <Grid container spacing={1}>
                {lore.places.map(place => (
                  <RelatedPlaceCard
                    key={place.id}
                    place={place}
                  />
                ))}
              </Grid>
            </section>
          }

          {lore.investigations.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Investigations into this story
                </Typography>
              <Grid container spacing={1}>
                {lore.investigations.map(investigation => (
                  <RelatedInvestigationCard
                    key={investigation.id}
                    investigation={investigation}
                  />
                ))}
              </Grid>
            </section>
          }

          {lore.tourCompanies.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Tours that explore this lore
                </Typography>
              <Grid container spacing={1}>
                {lore.tourCompanies.map(tourCompany => (
                  <RelatedTourCompanyCard
                    key={tourCompany.id}
                    tourCompany={tourCompany}
                  />
                ))}
              </Grid>
            </section>
          }

          {lore.books.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Books with info on this story
                </Typography>
              <Grid container spacing={1}>
                {lore.books.map(book => (
                  <RelatedBookCard
                    key={book.id}
                    book={book}
                  />
                ))}
              </Grid>
            </section>
          }

        </article>
      </Paper>
    </Layout>
  );
};

export const query = graphql`
  query singleLore($slug: String!) {
    gcms {
      lore(where: {slug: $slug}) {
        id
        title
        gallery {
          handle
          title
        }
        synopsis {
          markdown
          text
        }
        people {
          id
          name
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
        places {
          id
          name
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
        investigations {
          id
          title
          slug
          image {
            handle
            title
          }
        }
        tourCompanies {
          id
          name
          slug
          image {
            handle
            title
          }
        }
        books {
          id
          title
          slug
          image {
            handle
            title
          }
        }
      }
    }
  }
`;

export default Lore;



// const Lore = ({ data: { loading, error, lore } }) => {
//   const classes = useStyles();

//   if (error) return <h1>Error fetching the lore!</h1>;
//   if (!loading) {
//     return (
//       <Paper className={classes.articleContainer}>
//         <article>
//           <Typography variant="h3" gutterBottom={true}>
//             {lore.title}
//           </Typography>
//           <Gallery gallery={lore.gallery} />
//           <Markdown source={lore.synopsis.markdown} escapeHtml={false} />
//           <Divider variant="middle" />
//           {lore.people.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 People in the story
//               </Typography>
//               <Grid container spacing={1}>
//                 {lore.people.map(person => (
//                   <RelatedCard
//                     title={person.name}
//                     link={`/person/${person.slug}`}
//                     imageHandle={person.gallery[0].handle}
//                     imageTitle={person.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {lore.places.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Places in the story
//               </Typography>
//               <Grid container spacing={1}>
//                 {lore.places.map(place => (
//                   <RelatedCard
//                     title={place.name}
//                     link={`/place/${place.slug}`}
//                     imageHandle={place.gallery[0].handle}
//                     imageTitle={place.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {lore.investigations.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Investigations into this story
//               </Typography>
//               <Grid container spacing={1}>
//                 {lore.investigations.map(investigation => (
//                   <RelatedCard
//                     title={investigation.title}
//                     link={`/investigation/${investigation.slug}`}
//                     imageHandle={investigation.image.handle}
//                     imageTitle={investigation.image.title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {lore.tourCompanies.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Tours that explore this lore
//               </Typography>
//               <Grid container spacing={1}>
//                 {lore.tourCompanies.map(tourCompany => (
//                   <RelatedCard
//                     title={tourCompany.name}
//                     link={`/tourcompany/${tourCompany.slug}`}
//                     imageHandle={tourCompany.image.handle}
//                     imageTitle={tourCompany.image.title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {lore.books.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Books with info on this story
//               </Typography>
//               <Grid container spacing={1}>
//                 {lore.books.map(book => (
//                   <RelatedCard
//                     title={book.title}
//                     link={`/book/${book.slug}`}
//                     imageHandle={book.image.handle}
//                     imageTitle={book.image.title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//         </article>
//       </Paper>
//     );
//   }
//   return <h2>Loading lore...</h2>;
// };

// export const singleLore = gql`
//   query singleLore($slug: String!) {
//     lore(where: {slug: $slug}) {
//       id
//       title
//       gallery {
//         handle
//         title
//       }
//       synopsis {
//         markdown
//       }
//       people {
//         name
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//       places {
//         name
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//       investigations {
//         title
//         slug
//         image {
//           handle
//           title
//         }
//       }
//       tourCompanies {
//         name
//         slug
//         image {
//           handle
//           title
//         }
//       }
//       books {
//         title
//         slug
//         image {
//           handle
//           title
//         }
//       }
//     }
//   }
// `;

// export default graphql(singleLore, {
//   options: ({ match }) => ({
//     variables: {
//       slug: match.params.slug,
//     },
//   }),
// })(Lore);